import React, { useState, useRef, useEffect, useCallback } from "react";
import {

  FaStop, FaSpinner
} from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileWord, faFileExcel, faFilePowerpoint } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Imag from '../../components/images/gallery.png'
import Clip from '../../components/images/attachment.png'
import Send from '../../components/images/send.png'
import Mic from '../../components/images/mic.png'
import vmsg from "vmsg";
import Modal from "react-modal";
import "../../chat/widgets/chatinputs.css";
import { green } from "@mui/material/colors";
import FileViewer from 'react-file-viewer';
import { FaFilePdf } from 'react-icons/fa';

const ChatInput = ({
  onSendMessage,
  onSendImage,
  onSendDoc,
  onSendVoice
}) => {
  const [message, setMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [previewCaption, setPreviewCaption] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const fileInputRef = useRef(null);
  const docInputRef = useRef(null);
  const [isMessageSending, setMessageSending] = useState(false);
  const [previewFilePath, setPreviewFilePath] = useState(null);
  const [fileType, setFileType] = useState('');

  const recorderRef = useRef(
    new vmsg.Recorder({
      wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm"
    })
  );
  useEffect(() => {
    if (showSuccessMessage) {
      toast("Message send successfully", {
        className: "text-success",
        left: "50vw",
        right: "50vw",
        bottom: "5vh",
        autoClose: 5000, // Toast will close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        color: green
      });
      handleClosePopup(); // Optional: Reset state or close modal
    }
  }, [showSuccessMessage, popupMessage, isMessageSending]);

  const handleSendMessage = () => {
    if (message.trim()) {
      setMessageSending(true);
      setShowSuccessMessage(true); // Show success message
      onSendMessage(message); // Call to send message function
      setMessage(""); // Clear message input field

      // Add a delay before setting 'setMessageSending' to false
      setTimeout(() => {
        setMessageSending(false); // Hide after 2 seconds
      }, 2000); // 2000 ms = 2 seconds
    }
  };

  const handleEmojiClick = (event, emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setPopupMessage("Image must be less than 5MB");
        e.target.value = null; // Reset the file input field
        return;
      }
      setPreviewFilePath(URL.createObjectURL(file));
      console.log(file);
      setPreviewFile(file);
      e.target.value = null; // Reset the file input field
    }
  };

  const handleDocUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 15 * 1024 * 1024) {
        setPopupMessage("Document must be less than 15MB");
        e.target.value = null; // Reset the file input field
        return;
      }
      const fileExtension = file.name.split('.').pop().toLowerCase();
      setFileType(fileExtension);
      setPreviewFilePath(URL.createObjectURL(file));
      setPreviewFile(file);
      e.target.value = null; // Reset the file input field
    }
  };

  const startRecording = async () => {
    setIsRecording(true);
    try {
      await recorderRef.current.initAudio();
      await recorderRef.current.initWorker();
      recorderRef.current.startRecording();
    } catch (e) {
      console.error(e);
      setIsRecording(false);
    }
  };

  const stopRecording = async () => {
    setIsRecording(false);
    try {
      const blob = await recorderRef.current.stopRecording();
      if (blob.size > 5 * 1024 * 1024) {
        setPopupMessage("Audio must be less than 5MB");
        setAudioUrl(null);
        return;
      }
      const audioUrl = URL.createObjectURL(blob);
      setAudioBlob(blob);
      setAudioUrl(audioUrl);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSendAudio = () => {
    if (audioBlob) {
      onSendVoice(audioBlob);
      setAudioBlob(null);
      setAudioUrl(null);
    }
  };

  const handleCancelAudio = () => {
    setAudioBlob(null);
    setAudioUrl(null);
  };

  const handleSendPreviewFile = () => {
    if (previewFile) {
      if (previewFile.type.startsWith("image/")) {
        onSendImage(previewFile, previewCaption);
      } else {
        onSendDoc(previewFile, previewCaption);
      }
      setPreviewFile(null);
      setPreviewCaption("");
    }
  };

  const handleClosePopup = () => {
    setPopupMessage("");
    setPreviewFile(null);
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (popupMessage && !event.target.closest(".popup-modal")) {
        handleClosePopup();
      }
      // Hide success message when clicking outside
      if (showSuccessMessage && !event.target.closest(".success-message")) {
        setShowSuccessMessage(false);
      }
    },
    [popupMessage, showSuccessMessage]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  const getFileIcon = (file) => {
    if (!file) return null;
    const fileExtension = file.name.split('.').pop().toLowerCase();

    switch (fileExtension) {
      case 'pdf':
        return faFilePdf;
      case 'doc':
      case 'docx':
        return faFileWord;
      case 'xls':
      case 'xlsx':
        return faFileExcel;
      case 'ppt':
      case 'pptx':
        return faFilePowerpoint;
      default:
        return faFileAlt; // Default file icon for other document types
    }
  };



  return (
    <div className="chat-input border-top" style={{ backgroundColor: 'transparent' }}>
      <div className="input-container">
        <img
          src={Imag}
          height={25}
          width={25}
          className="icon"
          onClick={() => docInputRef.current.click()}
        />
        <input
          type="file"
          ref={docInputRef}
          onChange={handleImageUpload}
          style={{ display: "none" }}
          accept="image/*"
        />
        <img
          src={Clip}
          height={25}
          width={25}
          className="icon"
          onClick={() => fileInputRef.current.click()}
        />
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleDocUpload}
          style={{ display: "none" }}
          accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
        />
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message"
          className="text-input"
          onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        {isRecording ? (
          <FaStop className="icon" style={{ color: 'red' }} onClick={stopRecording} />
        ) : (
          <img
            src={Mic}
            height={25}
            width={25} className="icon" onClick={startRecording} />
        )
        }
        {isMessageSending ? (<FaSpinner
          className="spinner"
          style={{ color: "#25D366", fontSize: "25px", animation: "spin 1s linear infinite" }} // Add animation and color
        />) : (<img
          src={Send}
          height={25}

          width={25} className="icon" onClick={handleSendMessage} />)}

      </div>

      {audioUrl && (
        <div className="preview-container pr-7">
          <audio controls src={audioUrl} />
          <div className="preview-buttons">
            <button onClick={handleSendAudio} className="btn btn-success">
              Send
            </button>
            <button
              onClick={handleCancelAudio}
              className="btn btn-danger ml-1"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {previewFile && (
        <div className="preview-container pr-7" style={{ flexDirection: "column" }}>
          {previewFile.type.startsWith("image/") ? (
            <div style={{ borderWidth: "2px", borderColor: "black" }}>
              <img src={previewFilePath} alt="file preview" style={{ width: "450px", height: "450px", padding: "10px" }} />
              {/* <p className="preview-file">{previewFile.name}</p> */}
            </div>
          ) : (
            <div>
              {fileType === "pdf" ? (

                <div style={{ width: '600px', height: '370px', overflow: 'hidden' }}>
                  <embed
                    src={`${previewFilePath}#toolbar=0&navpanes=0&page=1&scrollbar=0&view=FitH`}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                    style={{ border: 'none', overflow: 'hidden' }}
                  />
                </div>
              ) : (
                <div style={{ width: '600px', height: '370px', overflow: "hidden", alignContent: "center",justifyContent:"center"  ,flex: 1 }}>
                  <div class="fileviewer" >
                    <FontAwesomeIcon  icon={getFileIcon(previewFile)} size="10x" color="gray" />
                  </div>
                  <div class="fileviewertitle">
                    <p>{previewFile.name}</p>
                  </div>
                </div>
              )}
            </div>
          )
          }
          <hr />
          <div className="preview-buttons">
            <button onClick={handleSendPreviewFile} className="btn btn-success">
              Send
            </button>
            <button
              onClick={() => setPreviewFile(null)}
              className="btn btn-danger ml-1"
            >
              Close
            </button>
          </div>
        </div>
      )}
      {popupMessage && (
        <Modal
          isOpen={!!popupMessage}
          onRequestClose={handleClosePopup}
          className="popup-modal"
          overlayClassName="popup-modal-overlay"
          ariaHideApp={false}
        >
          <h2>Error Message:</h2>
          <div className="popup">
            <p>{popupMessage}</p>
          </div>
        </Modal>
      )}
      {/* <ToastContainer /> */}
    </div>
  );
};

export default ChatInput;
