import { FaExclamationCircle } from "react-icons/fa";
import { BsCheck2All, BsCheck2, BsClock } from "react-icons/bs";
import React, { useState } from "react";

export const RenderStatusIcon = ({ status, message, id }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleIconClick = () => {
    console.log("Icon clicked");
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const iconStyle = { fontSize: "17px", verticalAlign: "bottom" };
console.log(
  "Message Status and message",
  status, message, id);
  switch (status) {
    case "sent":
      return <BsCheck2 style={{ ...iconStyle, color: "#A6A6A6" }} title="Sent" />;

    case "delivered":
      return <BsCheck2All style={{ ...iconStyle, color: "#A6A6A6" }} title="Delivered" />;

    case "read":
      return (
        <div>
          <div onClick={handleIconClick} style={{ display: 'inline-block', cursor: "pointer" }}>
            <BsCheck2All style={{ ...iconStyle, color: "#34B7F1" }} title="Read" />
          </div>

          {isPopupVisible && (
            <div className="popup-overlay">
              <div className="popup-content">
                <h3 style={{ color: 'black' }}>Message Details</h3>
                <p><strong>ID:</strong> {id}</p>
                <p><strong>Text:</strong> {message}</p>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <button onClick={handleClosePopup} style={{ padding: '10px 20px' }}>Close</button>
                  <button onClick={handleClosePopup} style={{ padding: '10px 20px' }}>Try Again</button>
                </div>
              </div>
            </div>
          )}
        </div>
      );

    case "failed":
      return (
        <FaExclamationCircle
          style={{ ...iconStyle, color: "red" }}
          title="Failed"
          onClick={handleIconClick}
        />
      );

    default:
      return (
        <div>
          <div onClick={handleIconClick} style={{ display: 'inline-block', cursor: "pointer" }}>
            <BsCheck2All style={{ ...iconStyle, color: "#34B7F1" }} title="Read" />
          </div>
{/* 
          {isPopupVisible && (
            <div className="popup-overlay">
              <div className="popup-content">
                <h3 style={{ color: 'black' }}>Message Details</h3>
                <p><strong>ID:</strong> {id}</p>
                <p><strong>Text:</strong> {message}</p>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <button onClick={handleClosePopup} style={{ padding: '10px 20px' }}>Close</button>

                </div>
              </div>
            </div>
          )} */}
        </div>
      );
  }
};
