 import { Container, Row, Col } from 'react-bootstrap'; // Import Bootstrap components
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useMediaQuery } from 'react-responsive'

const dummyData = {
  "Jan": {
    "weekly": Array.from({ length: 5 }, (_, i) => Math.floor(Math.random() * 100)),
    "monthly": Array.from({ length: 12 }, () => Math.floor(Math.random() * 200)),
    "pie": [44, 55, 13, 43, 22]
  },
  "Feb": {
    "weekly": Array.from({ length: 5 }, (_, i) => Math.floor(Math.random() * 100)),
    "monthly": Array.from({ length: 12 }, () => Math.floor(Math.random() * 200)),
    "pie": [33, 45, 15, 32, 25]
  }
};

const weeks = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const ApexChart = ({ type, series, categories, title, labels, height, Labelcolor }) => {
  // Define dynamic colors for specific labels (regions in this case)
  const dynamicLabelColors = labels ? labels.map(label => {
    if (label.includes('Region 1')) return 'white'; // White for Region 1
    if (label.includes('Region 2')) return 'white'; // Green for Region 2
    if (label.includes('Region 3')) return 'white'; // Blue for Region 3
    if (label.includes('Region 4')) return 'white'; // Yellow for Region 4
    if (label.includes('Region 5')) return 'white'; // Pink for Region 5
    return '#FFFFFF'; // Default color (white) for other labels
  }) : [];

  const options = {
    chart: {
      type: type || 'line',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true
        },
        // Setting the toolbar colors to white
        // theme: 'light' // This will set the toolbar icons and buttons to white/light theme
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        // Apply dynamic colors for regions
        colors: dynamicLabelColors.length > 0 ? dynamicLabelColors : [Labelcolor] || ["#25D366"],
      },
    },
    xaxis: {
      categories: categories || [],
      position: 'bottom',
      labels: {
        style: {
          // Applying white color for x-axis labels
          colors: Array(categories ? categories.length : 0).fill("white"),
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100, // Adjust according to your data
      tickAmount: 5,
      labels: {
        style: {
          colors: ["#FFFFFF"],
        },
      },
    },
    title: {
      text: title || 'Chart Title',
      floating: false,
      align: 'left',
      style: {
        color: '#FFFFFF',
        fontSize: '18px',
        fontWeight: 'bold',
      },
    },
    labels: labels || [],
    legend: {
      show: true,
      labels: {
        colors: '#FFFFFF', // Change the legend text color to white
      },
    },
  };

  return (
    <div>
      {/* Custom CSS to change the toolbar icon colors */}
      <style>
        {`
          .apexcharts-toolbar svg {
            fill: white; /* Change to desired color */
          }
        `}
      </style>

      <div id="chart-container" style={{ position: 'relative', height: height || '100%' }}>
        <ReactApexChart 
          options={options} 
          series={series || []} 
          type={type || 'line'} 
          height={height || '100%'} 
        />
      </div>
    </div>
  );
};



const DashChart = () => {
  const dailyData = dummyData['Jan'].weekly; // Example data for daily chart
  const monthlyData = dummyData['Jan'].monthly; // Example data for monthly chart
  const pieData = dummyData['Jan'].pie; // Example data for pie chart
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1023px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
 
  return (
    <div className="container-fluid mt-2">
      <div className="row g-3">
        
        {/* Desktop layout */}
        {isDesktop && (
          <>
            <div className="col-4 d-flex align-items-stretch mb-3">
              <div className="card text-center shadow" style={{ backgroundColor: "#D7AE94", borderRadius: "10px", width: '100%'}}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <ApexChart
                    type="bar"
                    series={[{ name: 'Daily Visits', data: dailyData }]}
                    categories={weeks}
                    title="Daily Customer Visits"
                    height="350px"
                    labelColor="white"
                  />
                </div>
              </div>
            </div>

            <div className="col-4 d-flex align-items-stretch mb-3">
              <div className="card text-center shadow" style={{ backgroundColor: "#CACFF0", borderRadius: "10px", width: '100%'}}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <ApexChart
                    type="line"
                    series={[{ name: 'Monthly Visits', data: monthlyData }]}
                    categories={months}
                    title="Monthly Customer Visits"
                    height="350px"
                    labelColor="#25D366"
                  />
                </div>
              </div>
            </div>

            <div className="col-4 d-flex align-items-stretch mb-3">
              <div className="card text-center shadow" style={{ backgroundColor: "#D3C5E5", borderRadius: "10px", width: '100%'}}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <ApexChart
                    type="pie"
                    series={pieData}
                    labels={['Region 1', 'Region 2', 'Region 3', 'Region 4', 'Region 5']}
                    title="Customer Distribution"
                    height="350px"
                    labelColor="white"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {/* Tablet layout */}
        {isTablet && (
          <>
            <div className="col-6 d-flex align-items-stretch mb-3">
              <div className="card text-center shadow" style={{ backgroundColor: "#D7AE94", borderRadius: "10px",width: '100%'}}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <ApexChart
                    type="bar"
                    series={[{ name: 'Daily Visits', data: dailyData }]}
                    categories={weeks}
                    title="Daily Customer Visits"
                    height="300px"
                    labelColor="white"
                  />
                </div>
              </div>
            </div>

            <div className="col-6 d-flex align-items-stretch mb-3">
              <div className="card text-center shadow" style={{ backgroundColor: "#CACFF0", borderRadius: "10px",width: '100%'}}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <ApexChart
                    type="line"
                    series={[{ name: 'Monthly Visits', data: monthlyData }]}
                    categories={months}
                    title="Monthly Customer Visits"
                    height="300px"
                    labelColor="#25D366"
                  />
                </div>
              </div>
            </div>

            <div className="col-12 d-flex align-items-stretch mb-3">
              <div className="card text-center shadow" style={{ backgroundColor: "#D3C5E5", borderRadius: "10px", width: '100%'  }}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <ApexChart
                    type="pie"
                    series={pieData}
                    labels={['Region 1', 'Region 2', 'Region 3', 'Region 4', 'Region 5']}
                    title="Customer Distribution"
                    height="300px"
                    labelColor="white"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {/* Mobile layout */}
        {isMobile && (
          <>
            <div className="col-12 d-flex align-items-stretch mb-3">
              <div className="card text-center shadow" style={{ backgroundColor: "#D7AE94", borderRadius: "10px",width: '100%' }}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <ApexChart
                    type="bar"
                    series={[{ name: 'Daily Visits', data: dailyData }]}
                    categories={weeks}
                    title="Daily Customer Visits"
                    height="250px"
                    labelColor="white"
                  />
                </div>
              </div>
            </div>

            <div className="col-12 d-flex align-items-stretch mb-3">
              <div className="card text-center shadow" style={{ backgroundColor: "#CACFF0", borderRadius: "10px", width: '100%'}}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <ApexChart
                    type="line"
                    series={[{ name: 'Monthly Visits', data: monthlyData }]}
                    categories={months}
                    title="Monthly Customer Visits"
                    height="250px"
                    labelColor="#25D366"
                  />
                </div>
              </div>
            </div>

            <div className="col-12 d-flex align-items-stretch mb-3">
              <div className="card text-center shadow" style={{ backgroundColor: "#D3C5E5", borderRadius: "10px", width: '100%' }}>
                <div className="card-body d-flex flex-column justify-content-center">
                  <ApexChart
                    type="pie"
                    series={pieData}
                    labels={['Region 1', 'Region 2', 'Region 3', 'Region 4', 'Region 5']}
                    title="Customer Distribution"
                    height="250px"
                    labelColor="white"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
  

export default DashChart;
